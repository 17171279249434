import { AngieAppRoutes } from 'src/app/angie-app.routes';
import { NavigationGroupType, PrimaryNavigationItem } from 'src/app/core';

/**
 * Primary Navigation inside our app where we list out all of the available root level links
 * THIS ONE WILL BE REMOVED WHEN ADMIN NAV GOES LIVE
 */
export const primaryAdminNavigation: PrimaryNavigationItem[] = [
	{
		iconClass: 'lu-dashboard',
		label: 'lup.tabs.dashboard',
		url: `/${AngieAppRoutes.DASHBOARD}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 1,
		permissionCheck: ({ userService, stateService }) => {
			return (
				userService.isAdmin() ||
				userService.isLearner() ||
				(!stateService.portalMeta.my_learning_enabled && userService.isManager())
			);
		}
	},
	{
		iconClass: 'lu-dashboard',
		label: 'lup.manager_dashboard.label',
		url: `/${AngieAppRoutes.MANAGER_DASHBOARD}`,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 1,
		permissionCheck: ({ userService, stateService }) => {
			return (
				userService.isManager() &&
				stateService.portalMeta.manager_dashboard_enabled &&
				stateService.portalMeta.my_learning_enabled
			);
		}
	},
	{
		iconClass: 'lu-courses',
		label: 'lup.tabs.courses',
		url: `/${AngieAppRoutes.COURSES.COURSES}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 4,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin() || userService.isInstructor() || userService.isManagerWithInstructorPermissions();
		}
	},
	{
		iconClass: 'lu-enrollment',
		label: 'lup.tabs.enrollments',
		url: `/${AngieAppRoutes.ENROLLMENTS.BASE}/${AngieAppRoutes.ENROLLMENTS.NEW}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 5,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin() || (userService.isManager() && userService.canEnrollLearnerOnCourse());
		}
	},
	{
		iconClass: 'lu-ilt-center',
		label: 'tabs.live_learning',
		url: AngieAppRoutes.ILTs.TRAININGS_HOME_ROUTE,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 6,
		permissionCheck: ({ stateService, userService }) => {
			const { can_access_live_trainings } = stateService.portalMeta;
			const { trainings_read_only_enabled } = stateService.portalMeta;

			const isAdminOrInstructor = userService.isAdmin() || userService.isInstructor();
			const checkIfContainsPermission =
				userService.canActAsInstructor() ||
				userService.canManageTraining() ||
				userService.canManageSessions() ||
				userService.tutorCanCreateCourses();
			const canManagerAccessTrainings = userService.isManagerWithInstructorPermissions() && checkIfContainsPermission;
			return can_access_live_trainings && (isAdminOrInstructor || canManagerAccessTrainings);
		}
	},
	{
		iconClass: 'lu-learning-paths',
		label: 'lup.ljw.learning_journeys',
		url: `/${AngieAppRoutes.LEARNING_JOURNEY.LJ_HOME_ROUTE}`,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 7,
		permissionCheck: ({ userService }) => {
			return userService.canAccessLearningJourneys();
		}
	},
	{
		iconClass: 'lu-resources',
		label: 'lup.tabs.resources',
		url: `/${AngieAppRoutes.LEARNER_RESOURCE_LIST}`,
		external: true,
		group: NavigationGroupType.GROUP_B,
		orderIndex: 3,
		permissionCheck: ({ userService, stateService }) => {
			const { learner_resources_count } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && !!learner_resources_count && !stateService.portalMeta.learner_dashboard_enabled;
		}
	},
	{
		iconClass: 'lu-store',
		label: 'lup.tabs.store',
		url: `/${AngieAppRoutes.STORE}`,
		external: true,
		group: NavigationGroupType.GROUP_B,
		orderIndex: 2,
		permissionCheck: ({ stateService, userService }) => {
			const { ecomms_enabled } = stateService.portalMeta;
			const roleCheck =
				(userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner()) &&
				!stateService.portalMeta.learner_dashboard_enabled;
			return roleCheck && ecomms_enabled;
		}
	},
	{
		iconClass: 'lu-catalog',
		label: 'lup.tabs.catalog',
		url: `/${AngieAppRoutes.CATALOG}`,
		external: true,
		group: NavigationGroupType.GROUP_B,
		orderIndex: 1,
		permissionCheck: ({ stateService, userService }) => {
			const { catalog_enabled } = stateService.portalMeta;
			const roleCheck =
				(userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner()) &&
				!stateService.portalMeta.learner_dashboard_enabled;
			return roleCheck && catalog_enabled;
		}
	},
	{
		iconClass: 'lu-users',
		label: 'lup.tabs.users',
		url: `/${AngieAppRoutes.USERS.USERS}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 2,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin() || userService.isManager();
		}
	},

	{
		iconClass: 'lu-groups',
		label: 'lup.tabs.groups',
		url: `/${AngieAppRoutes.GROUPS}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 3,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin();
		}
	},
	{
		iconClass: 'lu-portals',
		label: 'lup.tabs.licenses',
		url: `/${AngieAppRoutes.CLIENTS}`,
		external: true,
		group: NavigationGroupType.GROUP_C,
		orderIndex: 3,
		permissionCheck: ({ stateService, userService }) => {
			const { show_clients_and_licenses } = stateService.portalMeta;
			return userService.isAdmin() && show_clients_and_licenses;
		}
	},
	{
		iconClass: 'lu-library',
		label: 'lup.tabs.components',
		url: `/${AngieAppRoutes.COMPONENTS}`,
		external: true,
		group: NavigationGroupType.GROUP_C,
		orderIndex: 1,
		permissionCheck: ({ stateService }) => {
			const { has_library } = stateService.portalMeta;
			return has_library;
		}
	},
	{
		iconClass: 'lu-certificates',
		label: 'lup.tabs.certifications',
		url: `/certifications`,
		external: true,
		group: NavigationGroupType.GROUP_C,
		orderIndex: 2,
		permissionCheck: ({ stateService, userService }) => {
			return userService.isAdmin() && !stateService.portalMeta.certificate_editor_redesign;
		}
	},
	{
		iconClass: 'lu-certificates',
		label: 'lup.tabs.certifications',
		url: `/${AngieAppRoutes.CERTIFICATES.CERTIFICATES}`,
		group: NavigationGroupType.GROUP_C,
		orderIndex: 2,
		permissionCheck: ({ stateService, userService }) => {
			return userService.isAdmin() && stateService.portalMeta.certificate_editor_redesign;
		}
	},
	{
		iconClass: 'lu-reports',
		label: 'lup.tabs.reports',
		url: `/${AngieAppRoutes.REPORTS_BUILDER}`,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 8,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin() || userService.isManager() || userService.isInstructor();
		}
	},
	{
		iconClass: 'lu-assignment',
		label: 'lup.audit_trails.tabs',
		url: `/${AngieAppRoutes.AUDIT_TRAILS}`,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 9,
		permissionCheck: ({ userService, stateService }) => {
			return userService.isAdmin();
		}
	},
	{
		iconClass: 'lu-session',
		label: 'lup.subnav.lup_dashboard_training_calendar',
		url: `/dashboard_training_calendar`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 10,

		permissionCheck: ({ userService, stateService }) => {
			const { enable_ilts, trainings_enabled, my_learning_enabled, show_calendar_on_dash } = stateService.portalMeta;

			return (
				(userService.isInstructor() || userService.isManager() || userService.isManagerWithInstructorPermissions()) &&
				(enable_ilts || trainings_enabled) &&
				my_learning_enabled &&
				show_calendar_on_dash
			);
		}
	},
	{
		iconClass: 'lu-settings',
		label: 'lup.tabs.settings',
		url: `/${AngieAppRoutes.SETTINGS}`,
		external: true,
		group: NavigationGroupType.GROUP_D,
		orderIndex: 1,
		permissionCheck: ({ userService }) => {
			return userService.isAdmin() && !!userService.AdminCanAccessPortalSettings();
		}
	},
	{
		iconClass: 'lu-forums',
		label: 'lup.tabs.forums',
		url: `/${AngieAppRoutes.FORUMS}`,
		external: true,
		group: NavigationGroupType.GROUP_B,
		orderIndex: 4,
		permissionCheck: ({ stateService, userService }) => {
			const { forums_enabled, learner_dashboard_enabled } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && forums_enabled && !learner_dashboard_enabled;
		}
	},
	{
		iconClass: 'lu-forums',
		label: 'lup.tabs.forums',
		url: `/${AngieAppRoutes.FORUMS}`,
		external: true,
		group: NavigationGroupType.GROUP_A,
		orderIndex: 9,
		permissionCheck: ({ stateService, userService }) => {
			const { learner_view_forums_enabled, forums_enabled, learner_dashboard_enabled } = stateService.portalMeta;
			const roleCheck =
				userService.isAdmin() || userService.isManager() || userService.isInstructor() || userService.isLearner();
			return roleCheck && forums_enabled && learner_dashboard_enabled && !learner_view_forums_enabled;
		}
	}
];
